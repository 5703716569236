import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'next-i18next'
import SwiperCore, {
  Autoplay,
  Controller,
  Navigation,
  Pagination,
} from 'swiper'
import { SwiperSlide } from 'swiper/react'
import getConfig from 'next/config'
import Image from 'next/image'
import handleViewport, { type InjectedViewportProps } from 'react-in-viewport'
/**
 * styles
 */
import 'swiper/css'
import 'swiper/css/pagination'
import * as Styled from './styled'
/**
 * ui
 */
import Input, { InputStyle } from 'src/components/Input'
import Button, { ButtonSize, ButtonStyle } from 'src/components/Button'
import Icon from 'src/components/Icon'
import { TModal } from 'src/components/Modal'
/**
 * api
 */
import { useSecureMutation } from 'src/api/secure'
/**
 * constants
 */
import { OFERTA, PERSONAL_DATA, PRIVACY_POLICY } from 'src/constants'
/**
 * hook
 * */
import useRecaptcha from 'src/hooks/useRecaptcha'
/**
 * context
 */
import { useAppDispatch, useAppState } from 'src/context/app'

const {
  publicRuntimeConfig: { STATIC_ASSETS_COMMON_URL },
} = getConfig()

const slidesData = [
  {
    title: 'Твои стабильные 3000 ₽ в день на скальпинге криптовалют',
    titleMaxWidth: 350,
    courseCategory: 'Криптовалюта',
    courseTitle: 'Скальпинг',
    backgroundColor:
      'linear-gradient(112.58deg, #f7eb9e 10.11%, #b3edff 96.08%)',
    backgroundImage: `${STATIC_ASSETS_COMMON_URL}/assets/images/light-main-slides-money-bg.png`,
    imagePath: `${STATIC_ASSETS_COMMON_URL}/assets/images/light-main-slides-money-v1.png`,
    imageWidth: 203,
    imageHeight: 218,
    imagePositionRight: 10,
    cardTextColor: 'white',
  },
  {
    title: 'Начни зарабатывать от 3000 ₽ в день работая удаленно',
    titleMaxWidth: 341,
    courseCategory: 'Маркетинг',
    courseTitle: 'Маркетплейсы',
    backgroundColor:
      'linear-gradient(112.58deg, #EABEFF 10.11%, #B3EDFF 96.08%)',
    backgroundImage: `${STATIC_ASSETS_COMMON_URL}/assets/images/light-main-slides-market-bg.png`,
    imagePath: `${STATIC_ASSETS_COMMON_URL}/assets/images/light-main-slides-market-v1.png`,
    imageWidth: 225,
    imageHeight: 223,
    imagePositionRight: -50,
  },
  {
    title: 'Стань менеджером WB и получай по 20000 ₽ за одного клиента',
    titleMaxWidth: 381,
    courseCategory: 'Маркетинг',
    courseTitle: 'Маркетплейсы',
    backgroundColor:
      'linear-gradient(123.04deg, #8DECB3 -0.82%, #B3EDFF 128.21%)',
    backgroundImage: `${STATIC_ASSETS_COMMON_URL}/assets/images/light-main-slides-figma-bg.png`,
    imagePath: `${STATIC_ASSETS_COMMON_URL}/assets/images/light-main-slides-figma-v1.png`,
    imageWidth: 201,
    imageHeight: 201,
    imagePositionRight: -40,
    cardTextColor: 'white',
  },
]

type TFormValue = {
  email: string
  privacyPolicy: boolean
  promotionalMailings: boolean
  personalData: boolean
}

export const Registration = () => {
  const dispatch = useAppDispatch()
  const { recaptcha } = useAppState()
  const { t } = useTranslation('common')
  const { t: tform } = useTranslation('components.form')
  const { handleRecaptcha } = useRecaptcha()
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { isValid, errors, isSubmitting, isDirty },
  } = useForm<TFormValue>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
      privacyPolicy: true,
      promotionalMailings: true,
      personalData: true,
    },
  })
  const [swiperCourses, setSwiperCourses] = React.useState<SwiperCore>()
  // mutations
  const registrationMutation = useSecureMutation.usePostRegistration()
  const handleRegistration = (body: any) => {
    setValue('email', '')

    return registrationMutation
      .mutateAsync(body)
      .then(() => {
        dispatch({
          type: 'SET_MODAL',
          modalShown: true,
          modalType: TModal.REGISTRATION_SUCCESS,
        })
      })
      .catch(err => {
        setValue('email', body.email)
        if (err.response?.data?.formValidationErrors?.email) {
          setError('email', {
            type: 'manual',
            message:
              err.response?.data?.formValidationErrors?.email[0].message ?? '',
          })
        }
        if (err.response?.data?.formValidationErrors?.recaptcha) {
          setError('email', {
            type: 'manual',
            //@ts-ignore
            message:
              err.response.data.formValidationErrors.recaptcha[0].message ?? '',
          })
        }
      })
  }
  const onSubmit: SubmitHandler<TFormValue> = async data => {
    if (!recaptcha) {
      return handleRegistration({
        ...data,
        mailConsent: data.personalData,
        recaptcha: '',
      })
    }
    if (recaptcha) {
      const token = await handleRecaptcha()
      if (!token) {
        throw new Error('Recaptcha is not valid yet')
      }

      return handleRegistration({
        ...data,
        mailConsent: data.personalData,
        recaptcha: token,
      })
    }
  }
  return (
    <Styled.Container>
      <Styled.Inner>
        <Styled.SlidesContainer>
          <Styled.Right
            onClick={() => swiperCourses?.slidePrev(800)}
            aria-label='previous'
          >
            <Icon name='arrow-down' />
          </Styled.Right>

          <Styled.Left
            onClick={() => {
              swiperCourses?.slideNext(800)
            }}
            aria-label='next'
          >
            <Icon name='arrow-down' />
          </Styled.Left>
          <Styled.Slides
            modules={[Controller, Navigation, Pagination, Autoplay]}
            controller={{ control: swiperCourses }}
            onSwiper={setSwiperCourses}
            slidesPerView={1}
            loop
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
          >
            {slidesData.map((slide, index) => (
              <SwiperSlide key={index} style={{ boxSizing: 'border-box' }}>
                <Styled.SlidesTitle titleMaxWidth={slide.titleMaxWidth}>
                  {slide.title}
                  <Styled.Price>
                    <Image
                      src={`${STATIC_ASSETS_COMMON_URL}/assets/images/light-main-slides-price.png`}
                      width={118}
                      height={81}
                      alt='price'
                      quality={100}
                    />
                  </Styled.Price>
                </Styled.SlidesTitle>
                <Styled.SlideCourse
                  $backgroundColor={slide.backgroundColor}
                  cardTextColor={slide.cardTextColor}
                >
                  <Image
                    src={slide.backgroundImage}
                    layout='fill'
                    objectFit='cover'
                    alt={slide.courseTitle}
                    quality={100}
                  />
                  <Styled.SlideCourseCategory>
                    {slide.courseCategory}
                  </Styled.SlideCourseCategory>
                  <Styled.SlideCourseTitle>
                    {slide.courseTitle}
                  </Styled.SlideCourseTitle>
                  <Styled.SlideCourseImage
                    imageWidth={slide.imageWidth}
                    imageHeight={slide.imageHeight}
                    imagePositionRight={slide.imagePositionRight}
                  >
                    <Image
                      src={slide.imagePath}
                      width={slide.imageWidth}
                      height={slide.imageHeight}
                      alt={slide.courseTitle}
                      quality={100}
                    />
                  </Styled.SlideCourseImage>
                </Styled.SlideCourse>
              </SwiperSlide>
            ))}
          </Styled.Slides>
        </Styled.SlidesContainer>

        <Styled.Registration onSubmit={handleSubmit(onSubmit)}>
          <Styled.RegistrationSubtitle>
            Хотите учиться бесплатно?
          </Styled.RegistrationSubtitle>
          <Styled.RegistrationTitle>
            Зарегистрируйся и получи доступ к бесплатным мастер-классам!
          </Styled.RegistrationTitle>

          <Styled.RegistrationControl>
            <Input
              errorMessage={errors?.email?.message}
              inputStyle={InputStyle.ROUNDED}
              type='email'
              placeholder='Введите почту'
              {...register('email', {
                required: {
                  value: true,
                  message: t('validations.common.required'),
                },
              })}
            />

            <Button
              buttonStyle={ButtonStyle.FILLED}
              size={ButtonSize.BLOCK}
              disabled={!isValid || isSubmitting || !isDirty}
              type='submit'
            >
              Зарегистрироваться
            </Button>
          </Styled.RegistrationControl>
          <Styled.Accept>
            <Trans
              t={tform}
              i18nKey='terms'
              components={{
                '1': (
                  <Styled.AcceptLink
                    target='_blank'
                    href={PRIVACY_POLICY}
                    rel='noreferrer'
                  />
                ),
                '2': (
                  <Styled.AcceptLink
                    target='_blank'
                    href={PERSONAL_DATA}
                    rel='noreferrer'
                  />
                ),
                '3': (
                  <Styled.AcceptLink
                    target='_blank'
                    href={OFERTA}
                    rel='noreferrer'
                  />
                ),
              }}
            />
          </Styled.Accept>
        </Styled.Registration>
      </Styled.Inner>
    </Styled.Container>
  )
}
const RegistrationViewport = (props: InjectedViewportProps<HTMLDivElement>) => {
  const { forwardedRef, enterCount } = props
  return (
    <div ref={forwardedRef}>{enterCount > 0 ? <Registration /> : null}</div>
  )
}
export default handleViewport(RegistrationViewport)
