import styled from 'styled-components'
import { Swiper } from 'swiper/react'
export const Container = styled.section`
  position: relative;
  margin: 0 auto;
  padding: 56px 14.5px;
  max-width: 100%;
  width: 100%;
  color: ${props => props.theme.colors.black};
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 50px 20px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    max-width: 1440px;
    padding: 50px 108px 50px 108px;
  }
`

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    flex-direction: row;
    justify-content: space-between;
    gap: 13px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    gap: 24px;
  }
`
export const SlidesContainer = styled.div`
  background: #e4fbff;
  border-radius: 20px;
  padding: 26px 13px;
  position: relative;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 35px;
    width: 50%;
  }
  @media ${({ theme }) => theme.devices.noteUp} {
    padding: 65px 30px;
  }
`
export const Slides = styled(Swiper)`
  padding-bottom: 25px;
  .swiper-pagination-bullet-active {
    background-color: ${props => props.theme.colors.primary} !important;
  }
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding-bottom: 0;
    .swiper-pagination-bullets {
      display: none;
    }
  }
`
export const Arrow = styled.button`
  border: 1px solid ${props => props.theme.colors.black};
  border-radius: 50px;
  position: absolute;
  cursor: pointer;
  z-index: 10;
  width: 28px;
  height: 28px;
  display: none;
  background: #e4fbff;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const Right = styled(Arrow)`
  left: 10%;
  top: 60%;
  transform: translate(-10%, -60%) rotate(90deg);
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    left: 10%;
    top: 60%;
    transform: translate(-10%, -60%) rotate(90deg);
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    left: 10%;
    top: 60%;
    transform: translate(-10%, -60%) rotate(90deg);
  }
`
export const Left = styled(Arrow)`
  right: 10%;
  top: 60%;
  transform: translate(-10%, -60%) rotate(-90deg);
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    right: 10%;
    top: 60%;
    transform: translate(-10%, -60%) rotate(-90deg);
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    right: 10%;
    top: 60%;
    transform: translate(-10%, -60%) rotate(-90deg);
  }
`

export const SlidesTitle = styled.h3<{ titleMaxWidth: number }>`
  max-width: 264px;
  text-align: center;
  margin: 0 auto;
  position: relative;
  ${props => props.theme.mixins.H5}
  margin-bottom: 60px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    max-width: 213px;
    ${props => props.theme.mixins.H6}
    margin-bottom: 30px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    max-width: ${({ titleMaxWidth }) => `${titleMaxWidth}px`};
    ${props => props.theme.mixins.H4}
    margin-bottom: 45px;
  }
`
export const Price = styled.div`
  position: absolute;
  top: 44px;
  right: 0;
  transform: rotate(-5.69deg);
  width: 80px;
  height: 55px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    top: 30px;
    right: -55px;
    width: 70px;
    height: 48px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    top: 25px;
    right: -88px;
    width: 118px;
    height: 81px;
  }
`
export const SlideCourse = styled.div<{
  $backgroundColor: string
  cardTextColor?: string
}>`
  background: ${props => props.$backgroundColor};
  border-radius: 20px;
  margin: 0 auto;
  height: 146px;
  padding-left: 19px;
  padding-top: 35px;
  position: relative;
  overflow: hidden;
  color: ${({ cardTextColor }) => (cardTextColor ? cardTextColor : 'black')};

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    max-width: 213px;
    height: 120px;
    padding-left: 20px;
    padding-top: 40px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    overflow: initial;
    max-width: 357px;
    height: 202px;
    padding-left: 33px;
    padding-top: 68px;
  }
`
export const SlideCourseCategory = styled.p`
  position: relative;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  @media ${({ theme }) => theme.devices.noteUp} {
    ${props => props.theme.mixins.text2}
    margin-bottom: 3.5px;
  }
`
export const SlideCourseTitle = styled.h4`
  position: relative;
  ${({ theme }) => theme.mixins.H6}
  @media ${({ theme }) => theme.devices.noteUp} {
    ${({ theme }) => theme.mixins.H4}
  }
`
export const SlideCourseImage = styled.div<{
  imageWidth: number
  imageHeight: number
  imagePositionRight: number
}>`
  position: absolute;
  right: 20px;
  bottom: 0;
  width: 130px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    right: 0;
    width: 90px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    right: ${props => props.imagePositionRight}px;
    width: ${props => props.imageWidth}px;
    height: ${props => props.imageHeight}px;
  }
`

export const Registration = styled.form`
  background: #f9fafb;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 26px 13px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    width: 50%;
    padding: 30px 38px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    padding: 52px 62px;
  }
`

export const RegistrationSubtitle = styled.p`
  ${props => props.theme.mixins.text3}

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${props => props.theme.mixins.text5}
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    ${props => props.theme.mixins.text1}
  }
`

export const RegistrationTitle = styled.h2`
  margin-top: 4px;

  ${props => props.theme.mixins.H4}
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin-top: 5px;
    ${props => props.theme.mixins.H5}
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    margin-top: 8px;
    ${props => props.theme.mixins.H3}
  }
`

export const RegistrationControl = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  gap: 9px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    gap: 16px;
  }

  @media ${({ theme }) => theme.devices.noteUp} {
    margin-top: auto;
    gap: 16px;
    margin-top: auto;
  }
`

export const Accept = styled.div`
  margin-top: 9px;
  width: fit-content;
  font-size: 0.75rem;
  line-height: 18px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black};
`

export const AcceptLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};
  &:visited {
    text-decoration: none;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`
